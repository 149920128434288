import React, { useState, useCallback, useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../../components/layout";
import useSearchStore from "../../utility/search-store";
import Icon from "../../components/elements/icon-svg";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import classNames from "classnames";
import "simplebar/dist/simplebar.min.css";
import pointer_inactive from "../../images/Pointer_single_inactive.svg";
import pointer_active from "../../images/Pointer_single_active.svg";
import pointer_center from "../../images/Pointer_Search_center.svg";
import HubRisultatiList from "../../components/fasce/hub-risultati-list";
import HubSearchBar from "../../components/elements/hub-search-bar";
import { useCentriAssistenzaDataLayer } from "../../utility/dataLayerUtils";

const libraries = ["geometry", "places"];

const center = {
  lat: 43.2426,
  lng: 11.606997,
};

const mapOptions = {
  maxZoom: 19,
  zoom: 5,
  disableDefaultUI: true,
  zoomControl: true,
  fullscreenControl: true,
};

const ResultPage = ({ location }) => {
  const [address, setAddress] = useState("");
  const [addressCoord, setAddressCoord] = useState("");
  const date = new Date();
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState();
  const [selected, setSelected] = useState(null);
  const [showNoResult, setshowNoResult] = useState(null);
  const [checkedService, setCheckedService] = useState([]);
  // const [checkedBrand, setCheckedBrand] = useState([]);
  const [searchStoreByAddress] = useSearchStore();
  const [centriAssistenzaDataLayerPush] = useCentriAssistenzaDataLayer();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("query")) {
      const loadResults = async (address) => {
        setLoading(true);
        try {
          const { markers, latitude, longitude } = await searchStoreByAddress(address);
          if (markers?.length) {
            setAddressCoord({ latitude, longitude });
            setResult(markers);
            setshowNoResult(null);
          } else {
            setshowNoResult({
              title: "Nella tua zona al momento non siamo presenti.",
              subTitle: "Prova ad effettuare una nuova ricerca",
            });
            setResult([]);
          }
        } catch (error) {
          console.error(error);
          setshowNoResult({
            title: "L'indirizzo inserito non è valido.",
            subTitle: "Prova ad effettuare una nuova ricerca",
          });
          setResult([]);
        }
        setLoading(false);
      };
      setAddress(params.get("query"));
      loadResults(params.get("query"));
    } else {
      console.warn("Missing query");
      navigate("/");
    }
  }, [location.search, searchStoreByAddress]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const initMaps = useCallback(function onLoad(map) {
    setMap(map);
  }, []);

  useEffect(() => {
    if (!!map && !!result) {
      const newBounds = new window.google.maps.LatLngBounds();
      result.forEach((item) => {
        const location = new window.google.maps.LatLng(item.lat, item.lon);
        newBounds.extend(location);
      });
      if (!!addressCoord) {
        const addressLocation = new window.google.maps.LatLng(
          addressCoord.latitude,
          addressCoord.longitude
        );
        newBounds.extend(addressLocation);
      }
      console.log("addressCoord, newBounds: ", addressCoord, newBounds);
      map.fitBounds(newBounds);
      window.google.maps.event.addListenerOnce(map, "bounds_changed", function () {
        if (!!addressCoord) {
          map.setCenter({ lat: addressCoord.latitude, lng: addressCoord.longitude });
        }
        map.setZoom(map.getZoom() - 1);
      });
    }
  }, [result, map, addressCoord]);

  const search = async (address) => {
    setSelected(null);
    setCheckedService([]);
    // setCheckedBrand([]);

    if (address) {
      const params = new URLSearchParams();
      params.set("query", `${address}`);
      navigate(`?${params.toString()}`);
    } else {
      console.warn("Missing address");
    }
  };

  const panTo = React.useCallback(
    ({ lat, lng, zoom = 16 }) => {
      map.panTo({ lat, lng });
      map.setZoom(zoom);
    },
    [map]
  );

  return (
    <Layout location={location}>
      {result && !loading ? (
        <>
          {isLoaded && (
            <section className="hub-risultati">
              <div className="hub-risultati__box">
                <HubSearchBar
                  search={search}
                  address={address}
                  cls={"hub-search-bar--result-page"}
                />
              </div>
              {showNoResult && !loading && (
                <div className="hub-risultati__no-results container">
                  <h2 className="hub-risultati__title">{showNoResult.title}</h2>
                  <h3 className="hub-risultati__subtitle">{showNoResult.subTitle}</h3>
                </div>
              )}
              {result?.length === 1 && (
                <h2 className="hub-risultati__title container">
                  In base alla tua posizione abbiamo trovato {result.length} risultato
                </h2>
              )}
              {result?.length > 1 && (
                <h2 className="hub-risultati__title container">
                  In base alla tua posizione abbiamo trovato {result.length} risultati
                </h2>
              )}
              {result?.length > 0 && (
                <div className="hub-risultati__results container">
                  <HubRisultatiList
                    panTo={panTo}
                    result={result}
                    checkedService={checkedService}
                    setCheckedService={setCheckedService}
                    // checkedBrand={checkedBrand}
                    // setCheckedBrand={setCheckedBrand}
                    selected={selected}
                    setSelected={setSelected}
                    centriAssistenzaDataLayerPush={centriAssistenzaDataLayerPush}
                  />
                  <div className="hub-risultati__map-container">
                    <GoogleMap
                      mapContainerClassName="hub-risultati__map"
                      options={mapOptions}
                      onLoad={initMaps}
                      center={center}
                    >
                      {addressCoord && (
                        <Marker
                          map={map}
                          title={address}
                          position={
                            new window.google.maps.LatLng(
                              addressCoord.latitude,
                              addressCoord.longitude
                            )
                          }
                          icon={{
                            url: pointer_center,
                          }}
                        />
                      )}
                      {!!result &&
                        result
                          .filter((center) =>
                            center.servizi.find((service) =>
                              checkedService.length > 0 ? checkedService.includes(service) : true
                            )
                          )
                          // .filter((center) =>
                          //   center.brand.find((brand) =>
                          //     checkedBrand.length > 0 ? checkedBrand.includes(brand) : true
                          //   )
                          // )
                          .map((store) => {
                            return (
                              <Marker
                                map={map}
                                key={store.id}
                                position={store.location}
                                title={store?.nome}
                                onClick={() => {
                                  setSelected(store);
                                }}
                                label={{
                                  text: store.id.toString(),
                                  className: "hub-risultati__marker-number",
                                }}
                                icon={
                                  selected?.id === store.id
                                    ? {
                                        url: pointer_active,
                                      }
                                    : {
                                        url: pointer_inactive,
                                      }
                                }
                              />
                            );
                          })}
                      {!!selected && (
                        <InfoWindow
                          position={selected.location}
                          clickable={true}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -55),
                          }}
                        >
                          <div className="hub-risultati__infowindow">
                            <button
                              className="hub-risultati__infowindow-close"
                              onClick={() => {
                                setSelected(null);
                              }}
                            >
                              <span></span>
                              <span></span>
                            </button>
                            {selected?.nome && (
                              <div className="hub-risultati__name">{selected.nome}</div>
                            )}
                            {selected?.tipologia && (
                              <div className="hub-risultati__info">
                                <div className="hub-risultati__info-name">
                                  <span className="hub-risultati__store-type">
                                    {selected.tipologia === "CAT"
                                      ? "Società del gruppo ENGIE"
                                      : "Partner ENGIE"}
                                  </span>
                                  <Icon
                                    name={
                                      selected.tipologia === "CAT" ? "engie-group" : "engie-partner"
                                    }
                                    cls={
                                      selected.tipologia === "CAT"
                                        ? "hub-risultati__engie-group"
                                        : "hub-risultati__engie-partner"
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {selected?.centroAutorizzato?.filter((brand) => brand !== "").length >
                              0 && (
                              <div className="hub-risultati__authorized-center">
                                <span className="hub-risultati__authorized-center hub-risultati__authorized-center--header">
                                  Centro autorizzato:&nbsp;
                                </span>
                                {selected?.centroAutorizzato
                                  ?.filter((brand) => brand !== "")
                                  .map((brand, key) =>
                                    key === selected.centroAutorizzato.length - 1 ? (
                                      <span key={key} className="hub-risultati__center-name">
                                        {brand.toLowerCase()}
                                      </span>
                                    ) : (
                                      <span key={key} className="hub-risultati__center-name">
                                        {brand.toLowerCase() + ", "}
                                      </span>
                                    )
                                  )}
                                <Icon name="crown" cls="hub-risultati__icon-center" />
                              </div>
                            )}
                            <div className="hub-risultati__info-window-body">
                              {(selected?.indirizzo ||
                                selected?.cap ||
                                selected?.localita ||
                                selected?.provincia) && (
                                <div className="hub-risultati__info-window-section">
                                  <span className="hub-risultati__info-window-heading">
                                    Indirizzo
                                  </span>
                                  <address className="hub-risultati__info-window-address">
                                    {selected?.indirizzo && <span>{selected.indirizzo + ","}</span>}
                                    {(selected?.cap ||
                                      selected?.localita ||
                                      selected?.provincia) && (
                                      <span>
                                        {selected?.cap ? selected.cap : ""}
                                        {selected?.localita ? " " + selected.localita : ""}
                                        {selected?.provincia ? " (" + selected.provincia + ")" : ""}
                                      </span>
                                    )}
                                  </address>
                                  <span className="hub-risultati__info-window-phone">
                                    {selected.telefono}
                                  </span>
                                </div>
                              )}
                              {selected?.orari && (
                                <div>
                                  <span className="hub-risultati__info-window-heading">
                                    Orari di apertura:
                                  </span>
                                  <div className="hub-risultati__info-window-time-table">
                                    <ul>
                                      {selected.orari.map((item, key) => (
                                        <li
                                          key={key}
                                          className={classNames(
                                            "hub-risultati__info-window-table-item",
                                            {
                                              "hub-risultati__info-window-table-item--active":
                                                date.getDay() === key + 1,
                                            }
                                          )}
                                        >
                                          <span>{item?.giorno}</span>
                                          <span>{item?.orario}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                            <button
                              onClick={() => {
                                window.open(
                                  `https://www.google.com/maps?saddr=${address}&daddr=${selected.nome} ${selected.indirizzo} ${selected.cap} ${selected.localita} ${selected.provincia}`,
                                  "_blank",
                                  "noreferrer"
                                );
                                centriAssistenzaDataLayerPush(
                                  "Indicazioni stradali",
                                  "Risultati",
                                  selected.nome
                                );
                              }}
                              className="hub-risultati__itinerary"
                            >
                              INDICAZIONI STRADALI
                            </button>
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </div>
                </div>
              )}
            </section>
          )}
        </>
      ) : (
        <div className="loader"></div>
      )}
    </Layout>
  );
};

export default ResultPage;
