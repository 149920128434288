import React, { useRef, useState, useMemo, useEffect, useCallback } from "react";
import classNames from "classnames";
import Icon from "../elements/icon-svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { graphql, useStaticQuery } from "gatsby";

const HubRisultatiList = ({
  panTo,
  result,
  checkedService,
  setCheckedService,
  // checkedBrand,
  // setCheckedBrand,
  selected,
  setSelected,
  centriAssistenzaDataLayerPush,
}) => {
  const [serviceFiltersOpen, setServiceFiltersOpen] = useState(false);
  // const [brandFiltersOpen, setBrandFiltersOpen] = useState(false);
  const [numberOfServiceFilters, setNumberOfServiceFilters] = useState(4);
  // const [numberOfBrandFilters, setNumberOfBrandFilters] = useState(4);
  const serviceCenterList = useRef();

  const { dataCard } = useStaticQuery(
    graphql`
      {
        dataCard: liferayJskCardLayout(liferayFields: { title: { eq: "Servizi offerti" } }) {
          card {
            content {
              cardKey {
                value
              }
              cardTitle {
                value
              }
            }
          }
        }
      }
    `
  );

  const handleCheckService = (event) => {
    let updatedList = [...checkedService];
    if (event.target.checked) {
      updatedList = [...checkedService, event.target.value];
    } else {
      updatedList.splice(checkedService.indexOf(event.target.value), 1);
    }
    setCheckedService(updatedList);
    setSelected(null);
  };

  // const handleCheckBrand = (event) => {
  //   let updatedList = [...checkedBrand];
  //   if (event.target.checked) {
  //     updatedList = [...checkedBrand, event.target.value];
  //   } else {
  //     updatedList.splice(checkedBrand.indexOf(event.target.value), 1);
  //   }
  //   setCheckedBrand(updatedList);
  //   setSelected(null);
  // };

  const serviceFilters = useMemo(() => {
    const filterMap = dataCard?.card?.reduce((res, item) => {
      res.set(item?.content?.cardKey?.value, {
        key: item?.content?.cardKey?.value,
        title: item?.content?.cardTitle?.value,
      });
      return res;
    }, new Map());
    const filterSet = new Set();
    result.forEach((center) => {
      center.servizi.forEach((filter) => {
        filterSet.add(filter);
      });
    });
    const objectFilters = [...filterSet].map((key) => filterMap.get(key)).filter(Boolean);
    return objectFilters;
  }, [result, dataCard]);

  // const brandFilters = useMemo(() => {
  //   const filterSet = new Set();
  //   result.forEach((center) => {
  //     center.brand.forEach((filter) => {
  //       filterSet.add(filter);
  //     });
  //   });
  //   const objectFilters = [...filterSet].map((key) => key).filter(Boolean);
  //   return objectFilters;
  // }, [result]);

  const scrollContainer = useCallback((indexOfSelectedStore, considerFilterMenu = true) => {
    let childrenElement = serviceCenterList?.current?.children?.[0]?.children;

    let filterHeight = childrenElement?.[0]?.clientHeight;

    let total = considerFilterMenu ? filterHeight : 0;

    for (let i = 1; i < indexOfSelectedStore; i++) {
      total += childrenElement?.[i]?.clientHeight;
    }

    serviceCenterList?.current?.scrollTo({
      top: total,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (selected) {
      scrollContainer(result.findIndex((store) => store.id === selected.id) + 1);
    }
  }, [selected, scrollContainer, result]);

  useEffect(() => {
    if (result) {
      scrollContainer(0, false);
      setServiceFiltersOpen(false);
      // setBrandFiltersOpen(false);
      setNumberOfServiceFilters(4);
      // setNumberOfBrandFilters(4);
    }
  }, [result, scrollContainer]);

  return (
    <SimpleBar
      className="hub-risultati__list-container"
      autoHide={false}
      scrollableNodeProps={{ ref: serviceCenterList }}
    >
      <div className="hub-risultati__filters">
        <div className="hub-risultati__filters-heading">
          <span
            className="hub-risultati__filter-title"
            onClick={() => {
              setServiceFiltersOpen(!serviceFiltersOpen);
            }}
            aria-hidden="true"
          >
            Servizi offerti
          </span>
          <span
            className="hub-risultati__filter-chevron"
            onClick={() => {
              setServiceFiltersOpen(!serviceFiltersOpen);
            }}
            aria-hidden="true"
          >
            <Icon name={serviceFiltersOpen ? "chevron-up" : "chevron-down"} />
          </span>
        </div>
        {serviceFilters?.length > 0 && (
          <ul
            className={classNames("hub-risultati__filters-container", {
              "hub-risultati__filters-container--opened": serviceFiltersOpen,
            })}
          >
            {serviceFilters.slice(0, numberOfServiceFilters).map((filter, key) => (
              <li key={key}>
                <label className="hub-risultati__filter">
                  <span className="hub-risultati__filter-item">{filter.title}</span>
                  <input
                    type="checkbox"
                    value={filter.key}
                    name={filter.key}
                    onChange={handleCheckService}
                  />
                  <span className="hub-risultati__filter-checkmark"></span>
                </label>
              </li>
            ))}
          </ul>
        )}
        {serviceFilters.length > numberOfServiceFilters && (
          <div
            className={classNames("hub-risultati__filter-load", {
              "hub-risultati__filter-load--opened": serviceFiltersOpen,
            })}
            onClick={() => setNumberOfServiceFilters(numberOfServiceFilters * 2)}
            aria-hidden="true"
          >
            <span className="hub-risultati__filter-load-text">Carica altro</span>
          </div>
        )}
      </div>
      {/* <div className="hub-risultati__filters">
        <div className="hub-risultati__filters-heading">
          <span
            className="hub-risultati__filter-title"
            onClick={() => {
              setBrandFiltersOpen(!brandFiltersOpen);
            }}
          >
            Marchi trattati
          </span>
          <span
            className="hub-risultati__filter-chevron"
            onClick={() => {
              setBrandFiltersOpen(!brandFiltersOpen);
            }}
          >
            <Icon name={brandFiltersOpen ? "chevron-up" : "chevron-down"} />
          </span>
        </div>
        {brandFilters?.length > 0 && (
          <ul
            className={classNames("hub-risultati__filters-container", {
              "hub-risultati__filters-container--opened": brandFiltersOpen,
            })}
          >
            {brandFilters.slice(0, numberOfBrandFilters).map((filter, key) => (
              <li key={key}>
                <label className="hub-risultati__filter">
                  <span className="hub-risultati__filter-item">{filter.toLowerCase()}</span>
                  <input type="checkbox" value={filter} name={filter} onChange={handleCheckBrand} />
                  <span className="hub-risultati__filter-checkmark"></span>
                </label>
              </li>
            ))}
          </ul>
        )}
        {brandFilters.length > numberOfBrandFilters && (
          <div
            className={classNames("hub-risultati__filter-load", {
              "hub-risultati__filter-load--opened": brandFiltersOpen,
            })}
            onClick={() => setNumberOfBrandFilters(numberOfBrandFilters * 2)}
          >
            <span className="hub-risultati__filter-load-text">Carica altro</span>
          </div>
        )}
      </div> */}
      {!!result &&
        result
          .filter((center) =>
            center.servizi.find((service) =>
              checkedService.length > 0 ? checkedService.includes(service) : true
            )
          )
          // .filter((center) =>
          //   center.brand.find((brand) =>
          //     checkedBrand.length > 0 ? checkedBrand.includes(brand) : true
          //   )
          // )
          .map((store) => (
            <div
              key={store.id}
              className={classNames("hub-risultati__list-item", {
                "hub-risultati__list-item--active": selected?.id === store.id,
              })}
              onClick={() => {
                if (selected?.id !== store?.id) {
                  panTo({
                    lat: store.lat,
                    lng: store.lon,
                  });
                  setSelected(store);
                }
              }}
              aria-hidden="true"
            >
              <span className="hub-risultati__number">{store.id + "."}</span>
              <div className="hub-risultati__container">
                {store?.nome && (
                  <div className="hub-risultati__store">
                    <span className="hub-risultati__name">{store.nome}</span>
                    <span className="hub-risultati__map-icon-store">
                      <Icon name="map-marker-alt" />
                    </span>
                  </div>
                )}
                <div className="hub-risultati__info">
                  {store?.tipologia && (
                    <div className="hub-risultati__info-name">
                      <span className="hub-risultati__store-type">
                        {store.tipologia === "CAT" ? "Società del gruppo ENGIE" : "Partner ENGIE"}
                      </span>
                      <Icon
                        name={store.tipologia === "CAT" ? "engie-group" : "engie-partner"}
                        cls={
                          store.tipologia === "CAT"
                            ? "hub-risultati__engie-group"
                            : "hub-risultati__engie-partner"
                        }
                      />
                    </div>
                  )}
                  {store?.distance && (
                    <span className="hub-risultati__distance">
                      {Math.round(store.distance / 1000)} Km da te
                    </span>
                  )}
                </div>
                {store?.centroAutorizzato?.filter((brand) => brand !== "").length > 0 && (
                  <div className="hub-risultati__authorized-center">
                    <span className="hub-risultati__authorized-center hub-risultati__authorized-center--header">
                      Centro autorizzato:&nbsp;
                    </span>
                    {store?.centroAutorizzato
                      ?.filter((brand) => brand !== "")
                      .map((brand, key) =>
                        key === store.centroAutorizzato.length - 1 ? (
                          <span key={key} className="hub-risultati__center-name">
                            {brand.toLowerCase()}
                          </span>
                        ) : (
                          <span key={key} className="hub-risultati__center-name">
                            {brand.toLowerCase() + ", "}
                          </span>
                        )
                      )}
                    <Icon name="crown" cls="hub-risultati__icon-center" />
                  </div>
                )}
                {(store?.indirizzo || store?.cap || store?.localita || store?.provincia) && (
                  <address className="hub-risultati__address">
                    {store?.indirizzo && <span>{store.indirizzo + ","}</span>}
                    {(store?.cap || store?.localita || store?.provincia) && (
                      <span>
                        {store?.cap ? store.cap + " - " : ""}
                        {store?.localita ? store.localita : ""}
                        {store?.provincia ? " (" + store.provincia + ")" : ""}
                      </span>
                    )}
                  </address>
                )}
                <div className="hub-risultati__actions">
                  {store?.url && (
                    <a
                      href={store.url}
                      target="_blank"
                      rel="noreferrer"
                      className="cta-link"
                      onClick={() =>
                        centriAssistenzaDataLayerPush("Vai alla pagina", "Risultati", store?.nome)
                      }
                    >
                      VAI ALLA PAGINA
                    </a>
                  )}
                  {store?.telefono && (
                    <a
                      href={`tel:${store.telefono}`}
                      className="hub-risultati__phone"
                      onClick={() =>
                        centriAssistenzaDataLayerPush("Chiama", "Risultati", store?.nome)
                      }
                    >
                      <span className="hub-risultati__phone-icon">
                        <Icon name="smartphone" />
                      </span>
                      <span>CHIAMA</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
    </SimpleBar>
  );
};

export default HubRisultatiList;
