import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";

const libraries = ["geometry", "places"];
const slugify = require(`slugify`);

const getValidMarkers = (lat, lng, data) => {
  const markersWithDistance = data.map((result) => ({
    ...result,
    distance: window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(lat, lng),
      new window.google.maps.LatLng(result.lat, result.lon)
    ),
  }));

  markersWithDistance.sort(function (firstMarker, secondMarker) {
    return firstMarker.distance - secondMarker.distance;
  });

  const mapMarkers = markersWithDistance
    .filter((item) => item.lat > -90 && item.lat < 90 && item.lon > -180 && item.lon < 180)
    .map((obj, i) => ({
      ...obj,
      id: i + 1,
      location: new window.google.maps.LatLng(obj.lat, obj.lon),
      url: `/centri-assistenza/${slugify(obj.nome, {
        lower: true,
        strict: true,
        remove: /[*+~.()'"!:@]/g,
      })}-${slugify(obj.localita, {
        lower: true,
        strict: true,
        remove: /[*+~.()'"!:@]/g,
      })}-${slugify(obj.cap, { lower: true, strict: true })}/`,
    }));

  console.log(mapMarkers);
  return mapMarkers;
};

const getMunicipality = (place) => {
  let municipality;
  for (let i = 0; i < place.address_components.length; i++) {
    for (let j = 0; j < place.address_components[i].types.length; j++) {
      if (
        place.address_components[i].types[j] === "administrative_area_level_3" ||
        place.address_components[i].types[j] === "locality"
      ) {
        municipality = place.address_components[i];
      }
    }
  }
  return municipality?.long_name || "";
};

const getPostalCode = (place) => {
  let postalCode;
  for (let i = 0; i < place.address_components.length; i++) {
    for (let j = 0; j < place.address_components[i].types.length; j++) {
      if (place.address_components[i].types[j] === "postal_code") {
        postalCode = place.address_components[i];
      }
    }
  }
  return postalCode?.long_name || "";
};

const searchStoreByAddress = async (address, googleMapsGeocoder) => {
  const result = await new Promise((resolve, reject) => {
    console.log("searchStoreByAddress: ", address);
    googleMapsGeocoder.geocode(
      { address: address, componentRestrictions: { country: "IT" } },
      function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const result = results[0];
          resolve(result);
        } else {
          reject("Google Geocode was not successful");
        }
      }
    );
  });

  const lat = result.geometry.location.lat();
  const lng = result.geometry.location.lng();
  const municipality = getMunicipality(result);
  const postalCode = getPostalCode(result);

  if (lat && lng) {
    if (postalCode !== "") {
      const response = await fetch(`${process.env.GATSBY_SEARCH_STORE_ENDPOINT}?cap=${postalCode}`);
      const data = await response.json();
      return {
        latitude: lat,
        longitude: lng,
        markers: getValidMarkers(lat, lng, data),
      };
    } else if (municipality !== "") {
      const response = await fetch(
        `${process.env.GATSBY_SEARCH_STORE_ENDPOINT}?comune=${municipality}`
      );
      const data = await response.json();

      return {
        latitude: lat,
        longitude: lng,
        markers: getValidMarkers(lat, lng, data),
      };
    } else {
      throw new Error("Google Geocode was not successful");
    }
  } else {
    throw new Error("Google Geocode was not successful");
  }
};

const useSearchStore = () => {
  const [googleMapsGeocoder, setGoogleMapsGeocoder] = useState();
  const [promiseCb, setPromiseCb] = useState();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      const gmObj = window.google.maps;
      if (!gmObj) return;
      const gmGeocoder = new gmObj.Geocoder();
      setGoogleMapsGeocoder(gmGeocoder);
      if (promiseCb) {
        promiseCb(gmGeocoder);
        setPromiseCb();
      }
    }
  }, [isLoaded, promiseCb]);

  const searchStore = useCallback(
    (address) => {
      if (googleMapsGeocoder) {
        return searchStoreByAddress(address, googleMapsGeocoder);
      } else {
        return new Promise((resolve, reject) => {
          setPromiseCb((gmGeocoder) => {
            if (gmGeocoder) {
              searchStoreByAddress(address, gmGeocoder).then(resolve).catch(reject);
            }
          });
        });
      }
    },
    [googleMapsGeocoder]
  );

  const searchByPosition = useCallback(
    (lat, lng) => {
      if (googleMapsGeocoder) {
        const latlng = new window.google.maps.LatLng(lat, lng);
        return new Promise((resolve, reject) => {
          googleMapsGeocoder.geocode({ latLng: latlng }, async (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              console.log(results);
              const address = results[0];
              const result = await searchStoreByAddress(address);
              resolve(result);
            } else {
              reject("Google Geocode was not successful");
            }
          });
        });
      }
    },
    [googleMapsGeocoder]
  );

  return [searchStore, searchByPosition];
};

export default useSearchStore;
